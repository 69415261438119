@import "../_vars.scss";

.react-flow {
  position: absolute;
  height: calc(100% - 50px);
  background: #f8f8f8;

  .react-flow__node {
    border-radius: 4px;
    text-align: center;
    //transition: box-shadow 0.1s ease-out;
    &.selected .nowheel {
      border: 2px solid green;
    }
    &:hover {
      z-index: 11 !important;
    }
  }
}

.react-flow__handle {
  border-radius: 0;
  transform: translateY(-49%) rotate(45deg);
  border: none;
  background: orange;
  right: -7px;
}

// Somehow edges can't be styled well in scss modules ??

.react-flow__edge-path-group {
  &:hover .react-flow__edge-path {
    stroke-dasharray: 6;
    z-index: 10;
    -webkit-animation: dashdraw 0.5s linear infinite;
    animation: dashdraw 0.5s linear infinite;
  }
  &:hover .hover-controls {
    opacity: 1;
    pointer-events: auto;
  }

  .hover-controls {
    right: 0;
    user-select: none;

    button {
      background: $edge-button-background-color;

      img {
        filter: invert(100%) sepia(93%) saturate(5000%) hue-rotate(367deg)
          brightness(106%) contrast(135%);
      }

      &:active {
        background-color: $edge-button-active-background-color !important;
      }

      &:hover {
        background: $edge-button-hover-background-color;
      }
    }
  }
}

.react-flow__controls {
  bottom: 10px;
}

.react-flow__connection {
  .react-flow__edge-path-group {
    // don't show hover controls when its a new edge
    & .hover-controls {
      display: none !important;
    }
    // always animate new connections
    &.react-flow__edge-path {
      stroke-dasharray: 6;
      z-index: 10;
      -webkit-animation: dashdraw 0.5s linear infinite;
      animation: dashdraw 0.5s linear infinite;
    }
  }
}

.react-flow__edge.selected .react-flow__edge-path {
  stroke: $edge-stroke-color !important;
}
.react-flow__edge-path {
  stroke: $edge-stroke-color !important;
  stroke-width: 2 !important;
}

.react-flow__edge-path-hidden {
  stroke: transparent;
  stroke-width: 10;
  pointer-events: visiblestroke;
  fill: none;
}
